import React, { useState } from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { MessagesDialogLoader } from "modules";

import { Grid } from "@mui/material";

import AssessmentStatusIcon from "./assessmentStatusIcon.js";
import AssessmentInfoLink from "./assessmentInfoLink.js";
import RubricDialog from "../rubric/rubricDialog.js";

import { courseData, ENUMS } from "bild-data";
import FullInstructionsDialog from "../instructions/fullInstructionsDialog.js";
import usePadlock from "bild-utils/padlock.js";
import { toaster } from "presentational/toasts/toasts.js";
import CompetencyProofStatusConfirmDialog from "./competencyProofStatusConfirmDialog.js";

const useStyles = makeStyles({
  statusIcons: { marginBottom: "0.5rem", textAlign: "center" },
  typo: { padding: "0.25rem 0rem 0.25rem 3rem", textTransform: "capitalize" },
  icon: { width: "25px", textAlign: "center" },
  link: { cursor: "pointer", "&:hover *": { opacity: 0.87 }, "&:hover span": { textDecoration: "underline" } }
});

function AssessmentInfo({
  competency,
  assessmentLevelId,
  rubric,
  additionalResources,
  instructions,
  courseId,
  competencyId,
  competencyProofId,
  enrollmentId,
  proofStatus,
  assessmentFlowId,
  assessmentFlowIcons,
  groupColor,
  callingUserId,
  studentId,
  lmUser,
  afUser,
  isAdmin,
  onCompetencyChange,
  ...props
}) {
  const classes = useStyles();
  const [showRubricSample, setShowRubricSample] = useState(false);
  const [instructionsDialog, setInstructionsDialog] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [channelUsers, setChannelUsers] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState(null);

  const padlock = usePadlock();

  const iconComponents = (assessmentFlowIcons || []).map((icon, i) => <AssessmentStatusIcon icon={icon} key={i} />);

  const status =
    proofStatus ||
    (assessmentFlowId === ENUMS.ASSESSMENT.FLOWS.AS_STAFF.ID
      ? ENUMS.ASSESSMENT.STATUSES.SIMA_STAFF_WAIT.DESCRIPTION
      : ENUMS.ASSESSMENT.STATUSES.ST_WAIT.DESCRIPTION);

  // TODO fix the way artifacts templates are downloaded
  const artifactTemplateURL = `/competencies/${competencyId}/users/${callingUserId}/create_artifact`;

  // Get additional resources if they exist
  const addRes = JSON.parse(additionalResources) || [];
  const addReComponents = addRes.map((ar, i) => (
    <AssessmentInfoLink icon="fa-file-download" name={ar.name} groupColor={groupColor} url={ar.url} key={i} />
  ));

  function _showMessages(users) {
    setChannelUsers(users);
    setShowMessages(true);
  }
  
  function _updateCompetencyProof() {
    const data = {
      // TODO: Antioch FE ?? -- use waitingOn role object or somehow decide which role this user is?
      id: competencyProofId
    };
    if (statusDialogText === "complete") {
      data["sendToComplete"] = true;
    }
    if (statusDialogText === "st_wait") {
      data["sendToStudentWait"] = true;
    }

    courseData.updateCompetencyProof(
      competencyProofId,
      data,
      res => {
        if (typeof onCompetencyChange === "function") onCompetencyChange(res);
        padlock.unlock();

        toaster.success("Competency Proof Status has been Updated.");
      },
      f => padlock.unlock() // Always executed, "unlock" assessment process
    );

    _closeStatusDialog();
  }

  function _closeStatusDialog() {
    setStatusDialog(false);
    setStatusDialogText(null);
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.statusIcons}>
        {iconComponents}
      </Grid>

      <AssessmentInfoLink icon="fa-history" name={status} groupColor={groupColor} statusIndicator />

      {instructions && (
        <AssessmentInfoLink icon="fa-ruler" name="Full Instructions" groupColor={groupColor} onClick={() => setInstructionsDialog(true)} />
      )}

      <AssessmentInfoLink icon="fa-th" name="Rubric Sample" groupColor={groupColor} onClick={() => setShowRubricSample(true)} />

      <AssessmentInfoLink
        icon="fa-file-download"
        name="Artifact Template"
        groupColor={groupColor}
        onClick={() => {
          downloadRequest(artifactTemplateURL, "Artifact Template");
        }}
      />

      {addReComponents}

      {studentId && studentId !== callingUserId && (
        <AssessmentInfoLink icon="fa-comments-alt" name="Discuss with Student" groupColor={groupColor} onClick={() => _showMessages([studentId])} />
      )}
      {lmUser && lmUser.id !== callingUserId && (
        <AssessmentInfoLink
          icon="fa-comments-alt"
          name="Discuss with Local Mentor"
          groupColor={groupColor}
          onClick={() => _showMessages([lmUser.id])}
        />
      )}
      {afUser && afUser.id !== callingUserId && (
        <AssessmentInfoLink
          icon="fa-comments-alt"
          name="Discuss with Associate Faculty"
          groupColor={groupColor}
          onClick={() => _showMessages([afUser.id])}
        />
      )}
      {isAdmin && competency.competencyProof.complete && (
        <AssessmentInfoLink
          icon="fa-fast-backward"
          name="Send back to Student"
          groupColor={groupColor}
          onClick={() => {
            setStatusDialogText("st_wait");
            setStatusDialog(true);
          }}
        />
      )}
      {isAdmin && !competency.competencyProof.complete && (
        <AssessmentInfoLink
          icon="fa-fast-forward"
          name="Send to Complete"
          groupColor={groupColor}
          onClick={() => {
            setStatusDialogText("complete");
            setStatusDialog(true);
          }}
        />
      )}
      <RubricDialog
        open={showRubricSample}
        onClose={() => setShowRubricSample(false)}
        assessmentLevel={assessmentLevelId}
        rubric={rubric}
        groupColor={groupColor}
      />
      <FullInstructionsDialog
        open={instructionsDialog}
        onClose={() => setInstructionsDialog(false)}
        instructions={instructions}
        groupColor={groupColor}
      />
      <MessagesDialogLoader
        open={showMessages}
        onClose={() => {
          setShowMessages(false);
        }}
        openMessageUsers={channelUsers}
        anchors={[{ asCourseId: courseId, asCompetencyProofId: competencyProofId, asEnrollmentId: enrollmentId, name: competency.name }]}
      />
      <CompetencyProofStatusConfirmDialog
        open={statusDialog}
        onClose={_closeStatusDialog}
        text={statusDialogText}
        onSubmit={_updateCompetencyProof}
      />
    </>
  );
}

export default AssessmentInfo;
